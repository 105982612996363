import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { Stakeholders } from '../../../js/lib/stakeholder/service';
import { SbxPipesModule } from '../pipes/pipes.module';
import { SbxIconModule } from '../sbx-icon/sbx-icon.module';
import { SbxLoaderModule } from '../sbx-loader/sbx-loader.module';
import { SbxPopoverModule } from '../sbx-popover/sbx-popover.module';
import { SbxStakeholderCardComponent } from './sbx-stakeholder-card.component';

@NgModule({
  imports: [
    CommonModule,
    SbxPopoverModule,
    SbxIconModule,
    SbxPipesModule,
    SbxLoaderModule,
  ],
  declarations: [SbxStakeholderCardComponent],
  exports: [SbxStakeholderCardComponent],
  providers: [
    {
      provide: Stakeholders,
      useFactory: ($injector) => $injector.get('Stakeholders'),
      deps: ['$injector'],
    },
  ],
})
export class SbxStakeholderCardModule {}
