<sbx-process-sidebar-block headerIcon="documentFilled">
  <ul class="sbx-info-list">
    <li *ngFor="let doc of documentsModel.$documents; trackBy: trackById">
      <span class="sbx-doc-title"> {{ doc.get('title') }} </span>
      <sbx-loader *ngIf="documentsModel.$loading" size="small"></sbx-loader>
      <a
        [class]="['sbx-no-select', 'sbx-edit-doc-' + doc.get('id')]"
        *ngIf="doc.get('$editable') && !documentsModel.$loading"
        (click)="openDocumentEditModal(doc)"
      >
        <sbx-icon type="edit" />
      </a>
      <sbx-document-download-dropdown
        [id]="doc.get('id')"
        [class]="'review-doc-' + doc.get('id')"
        [docId]="doc.get('id')"
        [canDownload]="doc.get('canDownload')"
        [canExport]="doc.get('canExport')"
        [canDownloadWatermarked]="doc.get('canDownloadWatermarked')"
        [fullSized]="false"
      />
    </li>
  </ul>
</sbx-process-sidebar-block>
