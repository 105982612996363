import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SbxButtonModule } from '../sbx-button/sbx-button.module';
import { SbxIconModule } from '../sbx-icon/sbx-icon.module';
import { SbxLoaderModule } from '../sbx-loader/sbx-loader.module';
import {
  SbxCollapsibleTableColumnTemplate,
  SbxCollapsibleTableComponent,
} from './sbx-collapsible-table.component';

@NgModule({
  imports: [CommonModule, SbxButtonModule, SbxIconModule, SbxLoaderModule],
  declarations: [SbxCollapsibleTableComponent, SbxCollapsibleTableColumnTemplate],
  exports: [SbxCollapsibleTableComponent, SbxCollapsibleTableColumnTemplate],
})
export class SbxCollapsibleTableModule {}
