<sbx-modal-header
  >You are leaving Fidelity Private Shares for another website.
</sbx-modal-header>

<sbx-modal-content>
  <p>
    The site owner is not affiliated with Fidelity Private Shares and is solely
    responsible for the information and services it provides. Fidelity Private Shares
    disclaims any liability arising from your use of such information or services. Review
    the new site's terms, conditions, and privacy policy, as they will be different from
    those of Fidelity Private Shares' sites.
  </p>
</sbx-modal-content>

<sbx-modal-footer>
  <sbx-button title="Continue" theme="primary" (click)="proceed()"></sbx-button>
  <sbx-button title="Back to Fidelity Private Shares" theme="secondary" (click)="back()"></sbx-button>
</sbx-modal-footer>
