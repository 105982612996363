<a
  [class]="theme"
  [ngClass]="{ 'sbx-disabled': disabled || loading, 'sbx-static': staticButton }"
  [sbxDisabled]="disabled || loading"
  [href]="href"
  [target]="target"
  *ngIf="href"
>
  <span class="sbx-content-container">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </span>
</a>

<button
  type="button"
  [class]="theme"
  [ngClass]="{ 'sbx-disabled': disabled || loading, 'sbx-static': staticButton }"
  [sbxDisabled]="disabled || loading"
  *ngIf="!href"
>
  <span class="sbx-content-container">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </span>
</button>

<ng-template #content>
  <span class="icon left" *ngIf="icon">
    <sbx-icon [type]="icon"></sbx-icon>
  </span>

  <span class="text" [ngClass]="{ 'sbx-invisible': loading }" *ngIf="title">{{
    title
  }}</span>
  <sbx-loader *ngIf="loading" size="small" position="absolute"></sbx-loader>

  <span class="icon right" *ngIf="iconRight">
    <sbx-icon [type]="iconRight"></sbx-icon>
  </span>
</ng-template>
