import { AppConfig } from '@/core/config/app.config';
import { SbxHttpClient } from '@/core/http';
import { SbxUrlService } from '@/core/url/url.service';
import { Downgrade } from '@/shared/downgrade';
import { Component, HostListener, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { SideMenuItem } from '@shoobx/types/webapi-v2';
import { SbxSideMenuService } from './sbx-side-menu.service';
import { distinctUntilChanged } from 'rxjs';

@Downgrade.Component('ngShoobx', 'sbx-side-menu')
@Component({
  selector: 'sbx-side-menu',
  templateUrl: './sbx-side-menu.component.html',
  styleUrls: ['./sbx-side-menu.component.scss'],
})
export class SbxSideMenuComponent {
  topMenuItems: SideMenuItem[] = [];
  bottomMenuItems: SideMenuItem[] = [];
  myOtherCompaniesMenuItem?: SideMenuItem = undefined;
  menuItemSelected?: SideMenuItem = undefined;
  isMobile = false;

  constructor(
    @Inject(Router) private router: Router,
    @Inject(SbxUrlService) public sbxUrlService: SbxUrlService,
    @Inject(SbxSideMenuService) public sbxSideMenuService: SbxSideMenuService,
    @Inject(SbxHttpClient) private sbxHttpClient: SbxHttpClient,
    @Inject(AppConfig) public appConfig: AppConfig,
  ) {
    this.appConfig.userInfo$
      .pipe(
        distinctUntilChanged((prev, curr) => prev?.currentStake === curr?.currentStake),
      )
      .subscribe(async () => {
        const { sideMenu } = await this.sbxSideMenuService.load();
        if (this.appConfig.userInfo?.stakes?.length > 0) {
          const stakes = this.appConfig.userInfo?.stakes?.map((stake) => ({
            title: stake.title,
            click: async () => {
              await this.appConfig.entityInit(stake.name);
              this.navigate(this.sbxUrlService.dashboardUrl({ withSpaPrefix: true }));
              this.close();
            },
          }));

          const viewAllCompanies = {
            title: 'View All Companies',
            click: () => {
              this.navigate('/spa/entities');
              this.close();
            },
          };

          this.myOtherCompaniesMenuItem = {
            id: 'my-other-companies',
            title: 'My Other Companies',
            icon: 'share',
            sections: [
              {
                items: [...stakes, viewAllCompanies],
              },
            ],
          };
        } else {
          this.myOtherCompaniesMenuItem = undefined;
        }

        sideMenu?.forEach((item) => {
          if (
            item.linksMenuMode === 'collapsing' &&
            item.sections.length === 1 &&
            item.sections[0].items.length === 1
          ) {
            item.title = item.sections[0].items[0].title;
          }
        });

        this.topMenuItems = sideMenu?.filter((item) => item.position === 'top');
        this.bottomMenuItems = sideMenu?.filter((item) => item.position === 'bottom');
      });
  }

  ngOnInit(): void {
    this.isMobile = window.innerWidth < 1024;
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.isMobile = window.innerWidth < 1024;
  }

  isSpaPage() {
    return Boolean(document.querySelector('sbx-app'));
  }

  navigate(url: string) {
    if (this.isSpaPage()) {
      const spaUrl = this.sbxUrlService.spaUrl(url);
      if (spaUrl !== null) {
        this.router.navigate([spaUrl.url], { queryParams: spaUrl.queryParams });
        return;
      }
      location.href = url;
    } else {
      location.href = url;
    }
  }

  handleItemClick(item: SideMenuItem) {
    if (
      item.linksMenuMode === 'collapsing' &&
      item.sections.length === 1 &&
      item.sections[0].items.length === 1
    ) {
      this.navigate(item.sections[0].items[0].link);
      this.close();
      return;
    }

    if (this.menuItemSelected?.id === item.id) {
      this.close();
      return;
    }

    this.sbxSideMenuService.open = true;
    this.menuItemSelected = item;
  }

  close() {
    this.sbxSideMenuService.open = false;
    this.menuItemSelected = undefined;
  }

  async logout() {
    await this.sbxHttpClient.root('2').post('auth/logout').toPromise();
    this.appConfig.reset();
    this.navigate('/spa/logout');
    this.close();
  }
}
