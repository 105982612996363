import { Component, Inject } from '@angular/core';
import { SbxModalModule } from '../../sbx-modal/sbx-modal.module';
import { SbxButtonModule } from '../../sbx-button/sbx-button.module';
import { SbxModalService } from '@/shared/sbx-modal/sbx-modal.service';

@Component({
  selector: 'sbx-external-link-interupt-warning-modal',
  templateUrl: './sbx-external-link-interupt-warning-modal.component.html',
  standalone: true,
  imports: [SbxModalModule, SbxButtonModule],
})
export class SbxExternalLinkInteruptWarningModalComponent {
  public url: URL;

  constructor(
    public sbxModalService: SbxModalService,
    @Inject(Window) private readonly window: Window,
  ) {}

  public proceed(): void {
    this.sbxModalService.close();
    this.window.open(this.url.toString(), '_blank');
  }

  public back(): void {
    this.sbxModalService.close();
  }
}
