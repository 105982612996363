import {
  Input,
  Directive,
  TemplateRef,
  ViewContainerRef,
  ComponentRef,
  ViewRef,
} from '@angular/core';
import { SbxLoaderComponent } from './sbx-loader.component';

@Directive({
  selector: '[sbxLoader]',
})
export class SbxLoaderDirective {
  private loading = false;
  private loader: ComponentRef<SbxLoaderComponent>;
  private views: ViewRef[];

  public constructor(
    private readonly templateRef: TemplateRef<unknown>,
    private readonly viewContainerRef: ViewContainerRef,
  ) {
    // initialize views in the component the directive is attached to.
    this.viewContainerRef.createEmbeddedView(this.templateRef);

    this.views = [];
  }

  @Input()
  public set sbxLoader(loading: boolean) {
    if (this.loading === loading) {
      return;
    }

    this.loading = loading;

    if (loading) {
      // detach and save all views on view container.
      let i = 0;
      while (this.viewContainerRef.length) {
        this.views[i++] = this.viewContainerRef.detach(0);
      }

      // attach loading spinner to first index.
      if (this.loader) {
        this.viewContainerRef.insert(this.loader.hostView, 0);
      } else {
        this.loader = this.viewContainerRef.createComponent(SbxLoaderComponent);
        this.loader.instance.hideText = true;
      }
    } else {
      // detach the loading spinner first.
      this.viewContainerRef.detach(0);

      // go through saved views and attach them again to the view container.
      let i = 0;
      while (i < this.views.length) {
        this.viewContainerRef.insert(this.views[i], i++);
      }

      // clear the view container.
      this.views = [];
    }
  }
}
