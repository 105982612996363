import { Component, Input } from '@angular/core';
import {
  GuardsCheckEnd,
  GuardsCheckStart,
  NavigationCancel,
  NavigationEnd,
  NavigationStart,
} from '@angular/router';
import { Downgrade } from '../downgrade';

const SELECTOR: string = 'sbx-loader';

@Component({
  selector: SELECTOR,
  templateUrl: 'sbx-loader.component.html',
  styleUrls: ['sbx-loader.component.scss'],
})
@Downgrade.Component('ngShoobx', SELECTOR)
export class SbxLoaderComponent {
  @Input()
  public size: 'large' | 'medium' | 'small' = 'medium';

  @Input()
  public position: 'relative' | 'fixed' | 'absolute' = 'relative';

  @Input()
  public background: boolean = false;

  @Input()
  public primaryText: string = 'Loading';

  @Input()
  public secondaryText: string = 'This could take a moment.';

  @Input()
  public hideText: boolean = false;
}

export function routerEventSpinnerStart(event) {
  return event instanceof NavigationStart || event instanceof GuardsCheckEnd;
}

export function routerEventSpinnerStop(event) {
  return (
    event instanceof NavigationEnd ||
    event instanceof NavigationCancel ||
    event instanceof GuardsCheckStart
  );
}
