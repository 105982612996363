import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { SbxGridModule } from '../sbx-grid/sbx-grid.module';
import { SbxIconModule } from '../sbx-icon/sbx-icon.module';
import { SbxLoaderModule } from '../sbx-loader/sbx-loader.module';
import { SbxPopoverModule } from '../sbx-popover/sbx-popover.module';
import { SbxPropertiesListModule } from '../sbx-properties-list/sbx-properties-list.module';
import { SbxDocumentCardComponent } from './sbx-document-card.component';
import { SbxDocumentCardService } from './sbx-document-card.service';

@NgModule({
  imports: [
    CommonModule,
    SbxPopoverModule,
    SbxIconModule,
    SbxPropertiesListModule,
    SbxGridModule,
    SbxLoaderModule,
  ],
  declarations: [SbxDocumentCardComponent],
  exports: [SbxDocumentCardComponent],
  providers: [SbxDocumentCardService],
})
export class SbxDocumentCardModule {
  static entryComponents = [SbxDocumentCardComponent];

  static forRoot(): ModuleWithProviders<SbxDocumentCardModule> {
    return {
      ngModule: SbxDocumentCardModule,
    };
  }
}
