<sbx-modal-header>
  <h4 class="modal-title">{{ title }}</h4>
</sbx-modal-header>

<sbx-modal-content [fixedHeight]="fixedHeight">
  <sbx-alert-banner
    *ngIf="globalError"
    [error]="true"
    [customText]="globalError"
  ></sbx-alert-banner>

  <div
    *ngIf="topMessageTemplate || topMessageText || topInfoMessageText"
    class="sbx-top-message-container"
  >
    <ng-container
      *ngTemplateOutlet="
        topMessageTemplate;
        context: { $implicit: topMessageTemplateContext, model: model, form: form }
      "
    ></ng-container>
    {{ topMessageText }}

    <div class="sbx-info-message">
      {{ topInfoMessageText }}
    </div>
  </div>

  <sbx-form
    [formName]="formName"
    [form]="form"
    [formFields]="formFields"
    [model]="model"
    [config]="config"
    [serverErrors]="errors"
    [readOnly]="readOnly"
    [updateUrl]="updateUrl"
    [documentReferenceUploadUrl]="documentReferenceUploadUrl"
    *ngIf="formFields"
    (modelChange)="handleModelChange($event)"
    (submit)="form.valid ? handleSave() : null"
  ></sbx-form>

  {{ bottomMessageText }}
  <ng-container
    *ngTemplateOutlet="
      bottomMessageTemplate;
      context: { $implicit: bottomMessageTemplateContext, model: model, form: form }
    "
  ></ng-container>
</sbx-modal-content>

<sbx-modal-footer>
  <sbx-button
    *ngIf="!readOnly"
    [loading]="loading"
    [disabled]="!form.valid"
    theme="primary"
    (click)="handleSave()"
    [title]="okButtonTitle"
  >
  </sbx-button>
  <sbx-button
    theme="secondary"
    [disabled]="loading"
    (click)="sbxModalService.dismiss()"
    [title]="readOnly ? closeButtonTitle : cancelButtonTitle"
  ></sbx-button>
</sbx-modal-footer>
