import { APP_BASE_HREF } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

/**
 * Enum with possible navigation scenarios
 */
export enum NavigationState {
  FROM_SPA_TO_SPA, // Angular 2 to Angular 2
  FROM_SPA_TO_NON_SPA, // Angular 2 to Angular.js, zope, and the rest
  FROM_NON_SPA_TO_SPA, // Angular.js, zope, and the rest to Angular 2
  FROM_NON_SPA_TO_NON_SPA, // Angular.js, zope, and the rest to Angular.js, zope, and the rest
  FROM_SHOOBX_TO_ALLOWED_DOMAIN, // Shoobx (both SPA and non-SPA) to allowed domain (external allowed and current)
  FROM_SHOOBX_TO_EXTERNAL, // Shoobx (both SPA and non-SPA) to external
}

/**
 * A list of allowed external domains that the application can interact with.
 *
 * This array contains the domains that are considered safe and trusted for
 * external interactions. Any domain not listed here should be treated with
 * caution and may be restricted from certain operations.
 *
 */
const ALLOWED_EXTERNAL_DOMAINS = [
  'fidelity.com',
  'nb.fidelity.com',
  'support.shoobx.com',
  'shoobx.zendesk.com',
  'info.shoobx.com',
  'app.shoobx.com',
  'blog.shoobx.com',
  'fidelityprivateshares.com',
  'privateshares.fidelity.com',
];

/**
 * This service selects a "NavigationState" by target and current URL
 */
@Injectable({ providedIn: 'root' })
export class SbxNavigationStateService {
  public constructor(
    @Inject(APP_BASE_HREF) private readonly baseHref: string,
    @Inject('Window') private readonly window: Window,
  ) {}

  /**
   * Get navigation state by passed URL
   */
  public getState(url: URL): NavigationState {
    const baseHref = this.baseHref;
    const location = this.window.location;

    const isTargetPageAllowedExternal = this.isTargetDomainAllowedExternal(url.origin);
    const isTargetPageExternal = this.isTargetPageExternal(url.origin, location.origin);

    if (isTargetPageAllowedExternal) {
      return NavigationState.FROM_SHOOBX_TO_ALLOWED_DOMAIN;
    }

    if (isTargetPageExternal) {
      return NavigationState.FROM_SHOOBX_TO_EXTERNAL;
    }

    const isCurrentPageSpa = this.isCurrentPageSpa(baseHref, location.pathname);
    const isTargetPageSpa = this.isTargetPageSpa(baseHref, url.pathname);

    if (isCurrentPageSpa && isTargetPageSpa) {
      return NavigationState.FROM_SPA_TO_SPA;
    } else if (isCurrentPageSpa && !isTargetPageSpa) {
      return NavigationState.FROM_SPA_TO_NON_SPA;
    } else if (!isCurrentPageSpa && isTargetPageSpa) {
      return NavigationState.FROM_NON_SPA_TO_SPA;
    } else if (!isCurrentPageSpa && !isTargetPageSpa) {
      return NavigationState.FROM_NON_SPA_TO_NON_SPA;
    }
  }

  public isNavigationTargetAllowed(url: URL): boolean {
    const currentLocationHost = this.window.location.host;
    const targetUrlHost = url.host;
    const isTargetDomainAllowedExternal =
      this.isTargetDomainAllowedExternal(targetUrlHost);
    const isTargetDomainInternal = !this.isTargetPageExternal(
      targetUrlHost,
      currentLocationHost,
    );

    return isTargetDomainAllowedExternal || isTargetDomainInternal;
  }

  private isTargetPageExternal(currentOrigin: string, targetOrigin: string): boolean {
    return currentOrigin !== targetOrigin;
  }

  private isCurrentPageSpa(baseHref: string, currentPathName: string): boolean {
    return currentPathName.startsWith(baseHref);
  }

  private isTargetPageSpa(baseHref: string, targetPathName: string): boolean {
    return targetPathName.startsWith(baseHref);
  }

  private isTargetDomainAllowedExternal(url: string): boolean {
    return ALLOWED_EXTERNAL_DOMAINS.includes(url);
  }
}
