<div class="sbx-document-card sbx-document-card-{{ format }}">
  <div *ngIf="format !== 'full'" class="sbx-popover-container">
    <sbx-popover
      class="sbx-document-popover"
      sbxPopoverPlacement="auto"
      [sbxPopoverDisabled]="
        loading || !documentCard.id || (!documentCard.type && !documentCard.displayType)
      "
      [sbxPopoverContent]="popoverContent"
      [sbxPopoverClass]="'sbx-card-popover'"
    >
      <ng-container
        *ngTemplateOutlet="
          card;
          context: { $implicit: { documentCard, format, loading, showDocumentLinks } }
        "
      ></ng-container>
    </sbx-popover>
  </div>

  <sbx-row *ngIf="format === 'full'">
    <sbx-col [md]="6" [sm]="12">
      <ng-container
        *ngTemplateOutlet="
          card;
          context: { $implicit: { documentCard, format, loading, showDocumentLinks } }
        "
      ></ng-container>
    </sbx-col>

    <sbx-col [md]="6" [sm]="12">
      <ng-template
        *ngTemplateOutlet="
          propertiesList;
          context: { $implicit: { documentCard, showProminentMetadata } }
        "
      ></ng-template
    ></sbx-col>
  </sbx-row>
  <sbx-loader *ngIf="loading" size="small"></sbx-loader>
</div>

<ng-template #iconContent let-item>
  <sbx-icon
    class="sbx-document-icon"
    [type]="
      documentCard.displayType ? docIcons[documentCard.displayType] : 'documentFilled'
    "
    [ngClass]="{
      'sbx-doc-with-link': item.showLink,
      'sbx-large': item.large,
      'sbx-error': !documentCard.id
    }"
  ></sbx-icon>
</ng-template>

<ng-template #detailedOverviewContent let-item>
  <div class="sbx-card">
    <a *ngIf="item.showLink" class="sbx-document" [href]="getDocumentUrl()">
      <ng-container
        *ngTemplateOutlet="
          iconContent;
          context: { $implicit: { large: true, showLink: item.showLink } }
        "
      ></ng-container>
    </a>

    <span *ngIf="!item.showLink" class="sbx-document">
      <ng-container
        *ngTemplateOutlet="
          iconContent;
          context: { $implicit: { large: true, showLink: item.showLink } }
        "
      ></ng-container>
    </span>

    <div class="sbx-document-info">
      <a *ngIf="item.showLink" class="sbx-title" [href]="getDocumentUrl()">
        {{ documentCard.title }}
      </a>
      <span *ngIf="!item.showLink" class="sbx-title">
        {{ documentCard.title }}
      </span>
      <div class="sbx-details">
        <span class="sbx-details-item" *ngIf="documentCard.effectiveDate"
          ><sbx-icon type="calendarSolid"></sbx-icon>
          {{ documentCard.effectiveDate | date: 'MMMM d, yyyy' }}</span
        >
        <span class="sbx-details-item" *ngIf="documentCard.type === 'generated'"
          ><sbx-icon type="shoobx"></sbx-icon> Created in Fidelity Private Shares</span
        >
        <span class="sbx-details-item" *ngIf="documentCard.type === 'uploaded'"
          ><sbx-icon type="upload"></sbx-icon> Uploaded</span
        >
        <span class="sbx-details-item" *ngIf="documentCard.type === 'executed'"
          ><sbx-icon type="upload"></sbx-icon> Uploaded and Executed</span
        >
        <span class="sbx-details-item" *ngIf="documentCard.customizations">
          <sbx-icon type="pen"></sbx-icon>
          <ng-container [ngPlural]="documentCard.customizations">
            <ng-template ngPluralCase="=1"> 1 Customization </ng-template>
            <ng-template ngPluralCase="other">
              {{ documentCard.customizations }} Customizations
            </ng-template>
          </ng-container>
        </span>
        <span
          class="sbx-details-item"
          *ngIf="documentCard.status && documentCard.shouldDisplayStatus"
          ><sbx-icon type="briefcaseTime"></sbx-icon> Status:
          {{ documentCard.status }}</span
        >
      </div>
    </div>
  </div>
</ng-template>

<ng-template #popoverContent>
  <ng-template
    *ngTemplateOutlet="
      detailedOverviewContent;
      context: { $implicit: { showLink: false } }
    "
  ></ng-template>
  <ng-template
    *ngTemplateOutlet="
      propertiesList;
      context: { $implicit: { documentCard, showProminentMetadata } }
    "
  ></ng-template>
</ng-template>

<ng-template #card>
  <ng-container *ngIf="!loading && format !== 'detailed' && format !== 'full'">
    <div class="sbx-card">
      <a
        *ngIf="showDocumentLinks && documentCard.canView"
        class="sbx-document"
        [href]="getDocumentUrl()"
      >
        <ng-container
          *ngTemplateOutlet="
            iconContent;
            context: { $implicit: { large: false, showLink: true } }
          "
        ></ng-container>
      </a>
      <span *ngIf="!(showDocumentLinks && documentCard.canView)" class="sbx-document">
        <ng-container
          *ngTemplateOutlet="
            iconContent;
            context: { $implicit: { large: false, showLink: false } }
          "
        ></ng-container>
      </span>

      <div
        class="sbx-document-info-inline"
        *ngIf="format === 'standard' || format === 'lite'"
      >
        <a
          *ngIf="showDocumentLinks && documentCard.canView"
          class="sbx-title"
          [href]="getDocumentUrl()"
          >{{ documentCard.title }}</a
        >
        <span *ngIf="!(showDocumentLinks && documentCard.canView)" class="sbx-title">{{
          documentCard.title
        }}</span>

        <span
          *ngIf="format === 'standard' && documentCard.effectiveDate"
          class="sbx-date"
        >
          | {{ documentCard.effectiveDate | date: 'MMMM d, yyyy' }}
        </span>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="!loading && (format === 'detailed' || format === 'full')">
    <ng-container
      *ngTemplateOutlet="
        detailedOverviewContent;
        context: {
          $implicit: { showLink: showDocumentLinks && documentCard.canView }
        }
      "
    >
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #propertiesList>
  <sbx-properties-list
    *ngIf="documentCard.prominentMetadata && showProminentMetadata"
    [items]="documentCard.prominentMetadata"
    itemStyle="normal"
    layout="vertical"
  ></sbx-properties-list>
</ng-template>
