import {
  Inject,
  Injectable,
  Renderer2,
  RendererFactory2,
  TemplateRef,
} from '@angular/core';
import { SbxAngularRoutingService } from './sbx-angular-routing.service';
import { Router } from '@angular/router';
import {
  NavigationState,
  SbxNavigationStateService,
} from './sbx-navigation-state.service';
import { SbxUrlService } from '@/core/url/url.service';
import { SbxModalService } from '../sbx-modal/sbx-modal.service';
import { DOCUMENT } from '@angular/common';
import { ComponentType } from '@angular/cdk/portal';
import { SbxExternalLinkInteruptWarningModalComponent } from './sbx-external-link-interupt-warning-modal/sbx-external-link-interupt-warning-modal.component';

@Injectable({ providedIn: 'root' })
export class SbxNavigationService {
  private readonly renderer: Renderer2;

  public constructor(
    @Inject('Window') private readonly window: Window,
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly sbxNavigationStateService: SbxNavigationStateService,
    private readonly angularRoutingService: SbxAngularRoutingService,
    private readonly router: Router,
    private readonly urlService: SbxUrlService,
    private readonly rendererFactory: RendererFactory2,
    private readonly sbxModalService: SbxModalService,
  ) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
    this.addGlobalAnchorElementClickListener();
  }

  public navigate(routingFragments: string[]): void;
  public navigate(href: string): void;
  public navigate(reference: string | string[]): void {
    const href = Array.isArray(reference) ? reference.join('/') : reference;
    const url: URL = this.urlService.mapToUrl(href);
    const state = this.sbxNavigationStateService.getState(url);

    switch (state) {
      case NavigationState.FROM_SHOOBX_TO_EXTERNAL:
        return this.openDialog(url, SbxExternalLinkInteruptWarningModalComponent);
      case NavigationState.FROM_SPA_TO_SPA:
        return this.navigateByRouterLink(url);
      default:
        return this.loadPage(url);
    }
  }

  /**
   * Adds a global click event listener to the document.
   * This listener will handle click events on anchor elements.
   * The event handler is bound to the current instance of the service.
   */
  public addGlobalAnchorElementClickListener(): void {
    this.renderer.listen(this.document, 'click', this.clickEventHandler.bind(this));
  }

  /**
   * Leveraging Angular Router for navigation
   */
  private navigateByRouterLink(url: URL): void {
    const { routerLink, queryParams, fragment } =
      this.angularRoutingService.mapUrlToNavigationModel(url);

    this.router.navigate(routerLink, { queryParams, fragment });
  }

  /**
   * Loading page with Refreshing effect
   */
  private loadPage(url: URL): void {
    this.window.open(url.toString(), '_self');
  }

  private clickEventHandler(event: MouseEvent) {
    const targetElement = event.target as HTMLElement;
    if (targetElement.tagName.toLowerCase() !== 'a') {
      return;
    }

    const anchorElement = targetElement as HTMLAnchorElement;

    const anchorElementHref = anchorElement?.getAttribute('href');
    const anchorElementId = anchorElement?.getAttribute('id');
    const isMailToLink = anchorElementHref?.startsWith('mailto:');
    const isHashLink = anchorElementHref === '#';
    const isHrefEmpty = anchorElementHref === '';
    const isSbxNavigationLink = anchorElementId?.startsWith('sbx-navigation-link');
    const isSbxSideNavigationLink = anchorElementId?.startsWith('sbx-side-nav-link');
    const hasSbxRouterLinkDirective = anchorElement?.hasAttribute('ng-reflect-sbx-router-link');
    if (isMailToLink || isHashLink || isHrefEmpty || isSbxNavigationLink || isSbxSideNavigationLink || hasSbxRouterLinkDirective) {
      return;
    }

    event.preventDefault();

    const targetUrl = new URL(anchorElement.href);
    this.handleNavigation(targetUrl);
  }

  private openDialog(
    url: URL,
    content: TemplateRef<unknown> | ComponentType<unknown>,
  ): void {
    this.sbxModalService.open(content, {
      size: 'lg',
      data: { url },
    });
  }

  private handleNavigation(targetUrl: URL): void {
    const isNavigationAllowed =
      this.sbxNavigationStateService.isNavigationTargetAllowed(targetUrl);
    if (isNavigationAllowed) {
      this.loadPage(targetUrl);
    } else {
      this.openDialog(targetUrl, SbxExternalLinkInteruptWarningModalComponent);
    }
  }
}
