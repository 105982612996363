/**
 * @ngdoc component
 * @name sb.lib.messages.component:sbAlertBanner
 *
 * @description
 * This directive is a easy directive to show an alert/status banner. They are
 * "alerts" in bootstrap component lingo.
 *
 * @param {string} message The string content of the banner. *NOTE* setting this
 *   to dismissable will have a side effect of nulling this model.
 * @param {template} type Type of the banner, ie `danger`, `success`, or `info`.
 * @param {boolean} [dismissable=false] Boolean if the alert is dismissable. *NOTE*
 *   this is a one time binding.
 *
 * @example
   <sb-alert-banner
     type="danger"
     dismissable="false"
     message="message">
   </sb-alert-banner>
 */
export const sbAlertBanner = {
  template: require('./templates/alert-banner.html'),
  controllerAs: 'vm',
  bindings: {
    message: '=',
    type: '@',
    dismissable: '<',
  },
  controller: function () {
    this.handleClose = () => {
      this.message = null;
    };
  },
}; // end sbAlertBanner
