import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { SbxIconModule } from '../sbx-icon/sbx-icon.module';
import { SbxLoaderComponent } from './sbx-loader.component';
import { SbxLoaderDirective } from './sbx-loader.directive';

@NgModule({
  imports: [CommonModule, SbxIconModule],
  declarations: [SbxLoaderComponent, SbxLoaderDirective],
  exports: [SbxLoaderDirective, SbxLoaderComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SbxLoaderModule {}
