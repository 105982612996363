import { SbxScrollToElementRefDirective } from '@/shared/directives/scroll-to-element-ref.directive';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SbxAlertBannerModule } from '../sbx-alert-banner/sbx-alert-banner.module';
import { SbxGridModule } from '../sbx-grid/sbx-grid.module';
import { SbxIconModule } from '../sbx-icon/sbx-icon.module';
import { SbxLoaderModule } from '../sbx-loader/sbx-loader.module';
import { SbxPageComponent } from './sbx-page.component';

@NgModule({
  imports: [
    CommonModule,
    SbxAlertBannerModule,
    SbxGridModule,
    SbxIconModule,
    SbxScrollToElementRefDirective,
    SbxLoaderModule,
  ],
  declarations: [SbxPageComponent],
  exports: [SbxPageComponent],
})
export class SbxPageModule {}
