<ng-container *ngFor="let link of links; index as index">
  <div
    [routerLinkActive]="enableRouter ? 'selected' : ''"
    [class.selected]="activeIndex === index"
    class="sbx-nav-link"
  >
    <ng-container *ngIf="enableRouter">
      <a [routerLink]="link.path" (click)="handleLinkClick($event, link)" [id]="'sbx-side-nav-link-enaled-router-' + index">{{
        link.linkText
      }}</a>
    </ng-container>
    <ng-container *ngIf="!enableRouter">
      <a (click)="handleLinkClick($event, link)" [id]="'sbx-side-nav-link-disabled-router-' + index">{{ link.linkText }}</a>
    </ng-container>
  </div>
</ng-container>
