<pvd-alert
  #alertBanner
  class="sbx-alert-banner"
  [ngClass]="{ 'sbx-hidden': hidden }"
  [pvdTitle]="title"
  [pvdVariant]="type()"
  [pvdDismissible]="!disableClose"
  (pvd-alert-closed)="hide()"
>
  <span
    [innerHtml]="
      customText
        ? customText
        : error
          ? 'There was an issue saving your changes.'
          : 'Changes saved.'
    "
  >
  </span>
</pvd-alert>
