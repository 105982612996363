import { NgModule } from '@angular/core';
import { SbxPropertiesListComponent } from './sbx-properties-list.component';
import { CommonModule } from '@angular/common';
import { SbxPopoverModule } from '../sbx-popover/sbx-popover.module';
import { SbxIconModule } from '@/shared/sbx-icon/sbx-icon.module';

@NgModule({
  imports: [CommonModule, SbxPopoverModule, SbxIconModule],
  declarations: [SbxPropertiesListComponent],
  exports: [SbxPropertiesListComponent],
})
export class SbxPropertiesListModule {}
