import { SbxHttpClient } from '@/core/http';
import { inject, Injectable } from '@angular/core';
import { SideMenuResponse } from '@shoobx/types/webapi-v2';

@Injectable()
export class SbxSideMenuService {
  private sbxHttpClient = inject(SbxHttpClient);

  open = false;

  load() {
    return this.sbxHttpClient.entity('2').get<SideMenuResponse>('sidemenu').toPromise();
  }
}
