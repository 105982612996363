import { SbxHttpClient } from '@/core/http';
import { HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  AuthConvertResponse,
  AuthConvertRequest,
  AuthIdentificationMethod,
  AuthIdentificationMethodsResponse,
  AuthIdentifyRequest,
  AuthIdentifyResponse,
  AuthPostLoginResponse,
  AuthSendOTPRequest,
  AuthSendOTPResponse,
  AuthSignupConfigResponse,
  AuthConfigResponse,
} from '@shoobx/types/webapi-v2';
import { BehaviorSubject, Observable, tap } from 'rxjs';
import { AuthRoutePath } from './sbx-auth-routes';

export interface ResetPasswordStatus {
  status: string;
}

@Injectable()
export class SbxAuthService {
  private API_VERSION = '2';

  private _email: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public email$: Observable<string> = this._email.asObservable();

  private _availableIdentificationMethods: BehaviorSubject<AuthIdentificationMethod[]> =
    new BehaviorSubject<AuthIdentificationMethod[]>(null);
  public availableIdentificationMethods$: Observable<AuthIdentificationMethod[]> =
    this._availableIdentificationMethods.asObservable();

  private _cameFrom: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public cameFrom$: Observable<string> = this._cameFrom.asObservable();

  constructor(
    private readonly http: SbxHttpClient,
    private readonly router: Router,
    @Inject('Window') private readonly window: Window,
  ) {}

  public get email(): string {
    return this._email.value;
  }

  public set email(value: string) {
    this._email.next(value);
  }

  public get cameFrom(): string {
    return this._cameFrom.value;
  }

  public set cameFrom(value: string) {
    this._cameFrom.next(value);
  }

  public get availableIdentificationMethods(): AuthIdentificationMethod[] {
    return this._availableIdentificationMethods.value;
  }

  public set availableIdentificationMethods(value: AuthIdentificationMethod[]) {
    this._availableIdentificationMethods.next(value);
  }

  public postLogin(): Observable<AuthPostLoginResponse> {
    const url = 'auth/post-login';
    return this.http.root(this.API_VERSION).post<AuthPostLoginResponse>(url);
  }

  public identificationMethods(
    email: string,
  ): Observable<AuthIdentificationMethodsResponse> {
    this.email = email;
    const url = `auth/identification-methods`;
    const params = new HttpParams().set('email', email);
    return this.http
      .root(this.API_VERSION)
      .get<AuthIdentificationMethodsResponse>(url, { params })
      .pipe(
        tap({
          next: (res: AuthIdentificationMethodsResponse) => {
            this.availableIdentificationMethods = res?.methods;
          },
        }),
      );
  }

  public sendOTP(params: AuthSendOTPRequest): Observable<AuthSendOTPResponse> {
    const url = 'auth/otp';
    return this.http.root(this.API_VERSION).post<AuthSendOTPResponse>(url, { params });
  }

  public identifyUser(params: AuthIdentifyRequest): Observable<AuthIdentifyResponse> {
    const url = 'auth/identify';
    return this.http.root(this.API_VERSION).post<AuthIdentifyResponse>(url, { params });
  }

  public convert(params: AuthConvertRequest): Observable<AuthConvertResponse> {
    const url = 'auth/convert';
    return this.http.root(this.API_VERSION).post<AuthConvertResponse>(url, { params });
  }

  public signupConfig(): Observable<AuthSignupConfigResponse> {
    const url = 'auth/signup-config';
    return this.http.root(this.API_VERSION).get<AuthSignupConfigResponse>(url);
  }

  public loginConfig(
    secToken?: string,
    cameFrom?: string,
  ): Observable<AuthConfigResponse> {
    const url = 'auth/config';
    let params = new HttpParams();
    if (secToken) {
      params = params.set('sec_token', secToken);
    }
    if (cameFrom) {
      params = params.set('camefrom', cameFrom);
    }
    return this.http.root(this.API_VERSION).get<AuthConfigResponse>(url, { params });
  }

  public resetPassword(email: string): Observable<ResetPasswordStatus> {
    const url = 'password/reset';
    let params = new HttpParams();
    params = params.set('email', email);
    return this.http.root(this.API_VERSION).get(url, { params });
  }

  public getResetPasswordPageUrl(): string {
    const baseUrl = this.window.location.origin;
    return `${baseUrl}/spa/auth/${AuthRoutePath.passwordRecoveryFPSFlow}`;
  }
}
