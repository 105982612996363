<sbx-alert-banner [fadeTime]="0" #successBanner></sbx-alert-banner>

<sbx-row *ngIf="hasWarnings()">
  <sbx-col [lg]="12" *ngFor="let warning of getWarnings() | keyvalue">
    <sbx-alert-banner
      *ngIf="warning.value"
      [warning]="true"
      [customText]="warning.value"
      (close)="clearWarningFlag(warning.key)"
    ></sbx-alert-banner>
  </sbx-col>
</sbx-row>

<sbx-row *ngIf="hasErrors()">
  <sbx-col [lg]="12" *ngFor="let error of getErrors() | keyvalue; first as isFirst">
    <sbx-alert-banner
      *ngIf="error.value"
      sbxScrollToElementRef
      [sbxScrollToElementRefIsActive]="isFirst"
      [error]="true"
      [customText]="error.value"
      (close)="clearErrorFlag(error.key)"
    ></sbx-alert-banner>
  </sbx-col>
</sbx-row>

<ng-content></ng-content>

<sbx-loader
  *ngIf="isLoading()"
  position="fixed"
  size="large"
  [background]="true"
></sbx-loader>
